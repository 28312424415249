const BASE_URL = process.env.REACT_APP_BASE_URL;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

async function postApi(path, body, bearer) {
    const strBody = typeof body === 'string' ? body : JSON.stringify(body);
    const useBearer = bearer === false ? false : true;
    const params = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
//        mode: 'cors',
        body: strBody
    };
    if (useBearer) params.headers['Authorization'] = `BEARER ${localStorage.getItem('access_token')}`;
    const data = await fetch(`${BASE_URL}${path}`, params);
    const response = await data.json();

    if (data.status === 200) {
        return response.result;
    } else {
        console.log('ERROR:', data);
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

async function postFileApi(path, file, bearer) {
    const formData = new FormData();
    formData.append('file', file, file.name)
    const useBearer = bearer === false ? false : true;
    const params = {
        headers: {},
        credentials: 'include',
        method: 'POST',
//        mode: 'cors',
        body: formData
    };
    if (useBearer) params.headers['Authorization'] = `BEARER ${localStorage.getItem('access_token')}`;
    const data = await fetch(`${BASE_URL}${path}`, params);
    const response = await data.json();

    if (data.status === 200) {
        return response.result;
    } else {
        console.log('ERROR:', data);
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

async function patchApi(path, body) {
    const strBody = typeof body === 'string' ? body : JSON.stringify(body);
    console.log('patching body ' + strBody.length + ' bytes long', strBody);
    const data = await fetch(`${BASE_URL}${path}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `BEARER ${localStorage.getItem('access_token')}`
        },
        credentials: 'include',
        method: 'PATCH',
//        mode: 'cors',
        body: strBody
    });
    
    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

async function getApi(path, query) {
    const queryPart = query !== undefined ? `?${query}`: '';
    const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `BEARER ${localStorage.getItem('access_token')}`
        },
        credentials: 'include',
//        mode: 'cors',
        method: 'GET'
    });
    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(response.message);
        error.code = data.status;
        throw error;
    }
}

async function deleteApi(path, query) {
    const queryPart = query !== undefined ? `?${query}`: '';
    const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `BEARER ${localStorage.getItem('access_token')}`
        },
        credentials: 'include',
//        mode: 'cors',
        method: 'DELETE'
    });
    const response = await data.json();
    console.log('got after get:', response);
    if (data.status === 200) {
        return response.result;
    } else {
        console.log({data});
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

export class Users {

    async getSsoLink() {
        const link = await getApi('/users/get-sso-link');
        if(link){
            return link.SsoLink;
        }
        return null
    }

    async authenticateSso(code) {
        const body = { code };
        const user = await postApi('/users/authenticateSso', body);
        localStorage.setItem('current_user', JSON.stringify(user));
        localStorage.setItem('access_token', user.access_token)
        return user;
    }

    async authenticate(email, password) {
        const body = { email, password }; 
        const user = await postApi('/users/authenticate', body);
        localStorage.setItem('current_user', JSON.stringify(user));
        localStorage.setItem('access_token', user.access_token)
        return user;
    }

    async authenticateSecondStep(email, code) {
        const body = { email, code };
        const user = await postApi('/users/authenticate-second-step', body);
        localStorage.setItem('current_user', JSON.stringify(user));
        localStorage.setItem('access_token', user.access_token)
        return user;
    }
    
    async create(email, password) {
        const body = { email, password, username: email};
        const createdUser = await postApi('/users',  body);
        return createdUser;
    }

    async update(user) {
        return patchApi(`/users`, user);
    }

    async forgotPassword(email) {
        const a = await postApi('/users/forgotPassword', {email});
        return a;
    }

    async resetPassword(email, token, password) {
        const a = await postApi('/users/resetPassword', { email, token, password });
        return a;
    }
    
    async get(id) {
        return getApi(`/users/${id}`);
    }
}

export class Documents {
    async getAll(page_index, page_size) {
        return getApi(`/documents`, `page_index=${page_index}&page_size=${page_size}&document_category_id=null`);
    }

    async getAllForCategory(page_index, page_size, categoryId) {
        return getApi(`/documents`, `page_index=${page_index}&page_size=${page_size}&document_category_id=${categoryId}`);
    }

    async getAllDocumentsForStatistics(page_index, page_size, from , to) {
        return getApi(`/documents-statistic`, `page_index=${page_index}&page_size=${page_size}&from=${from}&to=${to}`);
    }

    async getAllDocumentsForStatisticsCount(id, from , to) {
        return getApi(`/organizations/${id}/statistic`, `from=${from}&to=${to}`);
    }

    async get(docId) {
        return getApi(`/documents/${docId}/anonymous`);
    }

    async rename(documentId, name) {
        const body = {name};
        return patchApi(`/documents/${documentId}`, body);
    }
    
    async delete(docId) {
        return await deleteApi(`/documents/${docId}`);
    }

    async post(docId, contact = {}) {
        return postApi(`/documents/${docId}/questionnaires`, contact);
    }

    async getContacts(wizardId) {
        return getApi(`/contacts/${wizardId}/map`);
    }
}

export class Organizations {
    async get(id) {
        if (!id) {
            console.log('id was empty', id);
            return;
        }
        return getApi(`/organizations/${id}`);
    }
    async update(updateDoc) {
        return await patchApi(`/organizations/${updateDoc.id}/`, updateDoc);
    }

    async getCategory() {
        return getApi(`/document-categories/`);
    }
}

export class Questionnaires {
    async get(questionnaireId) {
        return getApi(`/questionnaires/${questionnaireId}`);
    }

    async getAll(docId='', text='') {
        if(docId === '' ) {
            return getApi(`/questionnaires/search?text=${text}&page_size=9999`);
        }
        return getApi(`/questionnaires/search?text=${text}&document_id=${docId}&page_size=9999`);
    }

    async create(questionnaire) {
        return postApi(`/questionnaires`, questionnaire);
    }

    async post(questionnaireId, answer) {
        return patchApi(`/questionnaires/${questionnaireId}`, { answer });
    }

    async uploadFile(questionnaireId, file) {
        return postFileApi(`/questionnaires/${questionnaireId}/upload`,  file );
    }

    async delete(questionnaireId) {
        return await deleteApi(`/questionnaires/${questionnaireId}`);
    }
}

export class Files {
    async create(questionnaireId, documentId, answers, filename) {
        const body = {
            questionnaire_id: questionnaireId,
            document_id: documentId,
            answers,
            filename
        };
        // console.log("Posting this amazing body:", body);
        return postApi(`/files/`,  body);
    }

    async sign(fileId) {
        return getApi(`/files/${fileId}/sign`);
    }

    async uploadSignature(questionnaireId, documentId, answers, filename, base64_signature) {
        const body = {
            questionnaire_id: questionnaireId,
            document_id: documentId,
            answers,
            filename,
            base64_signature
        };
        return postApi(`/files/${questionnaireId}/uploadSignature`,  body);
    }

}

export class Contacts {
    async create(contact) {
        return postApi('/contacts/', contact);
    }

    // async findContacts(data) {
    //     return postApi(`/contacts/organization`, data);
    // }

    async update(contact) {
        return patchApi(`/contacts/${contact.id}/`, contact);
    }

    async delete(contactId) {
        return deleteApi(`/contacts/${contactId}/`);
    }

    async get(contactId) {
        return getApi(`/contacts/${contactId}/`);

    }

    async getAll(pageIndex, pageSize, sort, text) {
        return postApi('/contacts/organization', {
            sort,
            page_index: pageIndex,
            page_size: pageSize,
            text
        });
    }
}

const TheAPI = {
    users: new Users(),
    organizations: new Organizations(),
    files: new Files(),
    documents: new Documents(),
    questionnaires: new Questionnaires(),
    contacts: new Contacts()
};

export {TheAPI as default, SERVER_URL, BASE_URL};
